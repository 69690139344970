import React, { useContext, useState } from 'react';
import { Table } from 'antd';
import PageHeader from '../../components/page-header';
import {
  dataSourceDTO,
  getColumns,
  formMapper,
} from './utils';
import { testCases as testCasesActions } from '../../utils/firebase';
import TestCaseForm from './TestCaseForm';
import FormModal from '../../components/form-modal';
import { useCollection } from '../../utils/useFirebase';
import DataContext from '../../utils/DataContext';

const TestCases = () => {
  const testCases = useCollection('test-cases');
  const { organizations } = useContext(DataContext);

  const [showModal, setShowModal] = useState(false);
  const [testCase, setTestCase] = useState(null);
  const dataSource = testCases.map(testCase => dataSourceDTO(testCase));
  const { action = 'add', ...rawTestCase } = testCase || {};

  return (
    <>
      <PageHeader
        organizations={organizations}
        firebaseAction={testCasesActions}
        sectionName="Test Cases"
        Modal={({ showModal, setShowModal }) => (
          <FormModal
            organizations={organizations}
            showModal={showModal}
            setShowModal={setShowModal}
            onSave={testCasesActions.add}
            sectionName="Test Cases"
            id="test-cases-form"
            Form={TestCaseForm}
          />
        )}
      />
      <Table
        columns={getColumns({ setTestCase, setShowModal })}
        dataSource={dataSource}
        pagination={{ pageSize: 20 }}
      />
      {testCase && <FormModal
        organizations={organizations}
        showModal={showModal}
        setShowModal={setShowModal}
        data={rawTestCase}
        setData={setTestCase}
        onSave={testCasesActions[action]}
        sectionName="Test Cases"
        id="test-cases-form-modal"
        initialValuesMapper={formMapper}
        Form={TestCaseForm}
      />}
    </>
  );
};

export default TestCases;
