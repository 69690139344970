import StatusBadge from '../../components/status-badge';
import ActionButtons from '../../components/action-buttons';
import {
  alphabeticalOrder,
  filterDTO,
  valueEquality,
  getEnvironmentValue,
} from '../../utils';
import { user } from '../../utils/firebase';

const PRODUCTS = {
  CC: 'Command Center',
  EA: 'Enterprise Admin',
  JumpCloud: 'JumpCloud'
}

export const dataSourceDTO = (user, { environments, organizations, appUsers }) => ({
  raw: user,
  id: user.id,
  email: user.email,
  role: user.role,
  environment: getEnvironmentValue({
    env: environments[user.env],
    org: organizations[user.org],
  }),
  status: {
    available: user.available,
    availableInfo: user.availableInfo && appUsers[user.availableInfo.user],
    active: user.active,
  },
  product: user.product && PRODUCTS[user.product],
});

export const getColumns = ({
  environments,
  organizations,
  setUser,
  setShowModal,
}) => [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: alphabeticalOrder('email'),
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    sorter: alphabeticalOrder('role'),
  },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    sorter: alphabeticalOrder('role'),
    filters: Object.keys(PRODUCTS).map((key) => ({ text: PRODUCTS[key], value: PRODUCTS[key] })),
    onFilter: valueEquality('product'),
  },
  {
    title: 'Environment',
    dataIndex: 'environment',
    key: 'environment',
    filters: organizations
      .reduce((acc, { environments }) => acc.concat(environments.filter(({ envName }) => Boolean(envName))), [])
      .map(filterDTO('envName')),
    onFilter: valueEquality('environment'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: StatusBadge,
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (data) => (
      <ActionButtons
        setShowModal={setShowModal}
        setData={setUser}
        data={data}
        collection={user}
      />
    ),
  }
];

export const formMapper = (user) => ({
  env: user.env,
  org: user.org,
  email: user.email,
  password: user.password,
  product: user.product,
  role: user.role,
  triage: user.triage,
});
