import React from 'react';
import { Row, Col, Typography } from 'antd';

const Logo = ({ darkMode, style }) => (
  <Row align="middle" style={style}>
    <Col flex={'52px'}>
      <img alt="Pager Logo" src="/pager.png" width={42} />
    </Col>
    <Col>
      <Typography.Title
        level={4}
        style={{ margin: 0, ...(darkMode && { color: 'white' }) }}
        type="secondary"
        bold="true"
      >
        Automation Admin
      </Typography.Title>
    </Col>
  </Row>
);

export default Logo;
