import { formatDistance } from 'date-fns';
import StatusBadge from '../../components/status-badge';
import ActionButtons from '../../components/action-buttons';
import {
  alphabeticalOrder,
  filterDTO,
  valueEquality,
  getEnvironmentValue,
  statusEquality,
  statusFilterValues,
} from '../../utils';
import { patient } from '../../utils/firebase';
import { Link } from 'react-router-dom';
import { UserDeleteOutlined, UserOutlined, UserSwitchOutlined, WarningOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { get, capitalize } from 'lodash';

export const dataSourceDTO = (patient, { environments, organizations, appUsers }) => {
  let environmentName = 'N/A';
  if (organizations[patient.org]) {
    environmentName = (organizations[patient.org].name);
  }
  return ({
  raw: patient,
  id: patient.id,
  name: patient.authData.patient.name,
  environment: getEnvironmentValue({
    env: environments[patient.env],
    org: organizations[patient.org],
  }) || capitalize(environmentName),
  status: {
    available: patient.available,
    availableInfo: patient.availableInfo && appUsers[patient.availableInfo.user],
    isWorking: patient.state ? patient.state.isWorking : true,
    isWorkingError: patient.state && patient.state.error,
    lastUpdate: patient.state
      && patient.state.date
      && `Last update was ${formatDistance(new Date(), new Date(patient.state.date.seconds * 1000))} ago`,
    token: patient.state && patient.state.token,
    active: patient.active,
  },
  fake: patient.fake,
  mock: patient.mock,
})};

export const getColumns = ({
  environments,
  organizations,
  setPatient,
  setShowModal,
}) => [
  {
    title: 'Name',
    key: 'name',
    sorter: alphabeticalOrder('name'),
    filters: [
      { text: 'Fake', value: 'fake' },
      { text: 'Mock', value: 'mock' },
      { text: 'Test', value: 'test' },
      { text: 'Broken', value: 'broken' },
      { text: 'Not broken', value: 'notbroken' },
    ],
    onFilter: (value, record) => {
      if (value === 'broken') return !record.status.isWorking;
      if (value === 'notbroken') return record.status.isWorking;
      if (value === 'test') return !(record.fake && record.mock);

      return get(record, value);
    },
    render: ({
      name,
      id,
      status: {
        isWorking = true,
        lastUpdate,
        isWorkingError,
        active,
      },
      fake,
      mock,
    }) => <>
      {
        fake
          ? <Tooltip title="fake"><UserSwitchOutlined /></Tooltip>
          : (
            mock
              ? <Tooltip title="mock"><UserDeleteOutlined /></Tooltip>
              : <Tooltip title="test"><UserOutlined /></Tooltip>
          )
      }
      <Link to={`/patient/${id}`}> {name}</Link>
      {!isWorking && <Tooltip title={`Broken: ${isWorkingError}. ${lastUpdate ? lastUpdate : ''}`}> <WarningOutlined style={{ color: '#fa8c16' }} /></Tooltip>}
    </>,
  },
  {
    title: 'Environment',
    dataIndex: 'environment',
    key: 'environment',
    filters: organizations
      .reduce((acc, { environments }) => acc.concat(environments.filter(({ envName }) => Boolean(envName))), [])
      .map(filterDTO('envName')),
    onFilter: valueEquality('environment'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: StatusBadge,
    filters: statusFilterValues,
    onFilter: statusEquality,
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (data) => (
      <ActionButtons
        setShowModal={setShowModal}
        setData={setPatient}
        data={data}
        collection={patient}
      />
    ),
  }
];

export const formMapper = (patient) => ({
  name: patient.authData && patient.authData.patient && patient.authData.patient.name,
  password: patient.authData && patient.authData.patient && patient.authData.patient.password,
  type: patient.authData && patient.authData.patient && patient.authData.patient.type,
  env: patient.env,
  org: patient.org,
  code: patient.authData && patient.authData.code,
  data: patient.authData && patient.authData.data,
});
