import React, { useEffect, useState } from 'react';
import ApiUsers from './api-users';
import Environments from './environments';
import Organizations from './organizations';
import Patients from './patients';
import Users from './users';
import TestCases from './test-cases';
import TestRuns from './test-runs';
import TestRunView from './test-run-view';
import TestCaseView from './test-case-view';
import PatientView from './patient-view';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import {
  Menu,
  Layout,
  Avatar,
  Row,
  Col,
  Popover,
  Space,
  Button,
} from 'antd';
import {
  UserOutlined,
  GlobalOutlined,
  ToolOutlined,
  ForkOutlined,
  CloudOutlined,
  AuditOutlined,
} from '@ant-design/icons';
import firebaseApp from '../firebase';
import DataContext from '../utils/DataContext';
import Logo from '../components/logo';
import { useCollection } from '../utils/useFirebase';

const DOCS_URL = 'https://pagerinc.atlassian.net/wiki/spaces/EN/pages/1479442433/Automation+Admin';

const BaseApp = ({ user, signOut, firebaseAppAuth, providers }) => {
  const [appUser, setAppUser] = useState(null);
  useEffect(() => {
    const sendUserData = async () => {
      if (user) {
        const appUserInstance = await firebaseApp
          .firestore()
          .collection('app-users')
          .doc(user.email)
          .get();
        
        setAppUser(appUserInstance);
      }
    }
    
    sendUserData();
  }, [user]);

  useEffect(() => {
    if (!appUser || !user) return;

    const { photoURL } = appUser.data() || {};
  
    if (photoURL === user.photoURL) return;

    const unsubscribe = firebaseApp
      .firestore()
      .collection('app-users')
      .doc(user.email).set({
        displayName: user.displayName,
        photoURL: user.photoURL,
      });

    return () => unsubscribe();
  }, [appUser, user]);

  const environments = useCollection('environments');
  const organizations = useCollection('organizations');
  const [showPopover, setShowPopover] = useState(false);
  const data = {
    user,
    environments,
    organizations,
    firebaseAppAuth,
    providers,
  };

  return (
    <Router>
      <DataContext.Provider value={data}>
        <Layout style={{ minHeight: '100vh' }}>
          <Layout.Header style={{ padding: '0 24px' }}>
            <Row>
              <Col flex={1}>
                <Logo darkMode={true} />
              </Col>
              <Col style={{ paddingRight: 10 }}>
              <Button ghost>
                <a
                  href={DOCS_URL}
                  target="_blank"
                  rel="noreferrer"
                >Go to docs</a>
              </Button>
              </Col>
              <Col>
                <Popover
                  content={(
                    <Button type="link" onClick={signOut}>Sign out</Button>
                  )}
                  title="Profile actions"
                  trigger="click"
                  visible={showPopover}
                  onVisibleChange={setShowPopover}
                >
                  <div style={{ cursor: 'pointer' }}>
                    <Space>
                      <Avatar src={user.photoURL} icon={<UserOutlined />}/>
                    </Space>
                  </div>
                </Popover>
              </Col>
            </Row>
          </Layout.Header>
          <Layout>
            <Layout.Sider width={256}>
              <Menu
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                mode="inline"
                style={{ height: '100%', borderRight: 0 }}
              >
                <Menu.Item key="api" icon={<CloudOutlined />}>
                  <Link to="/">API keys</Link>
                </Menu.Item>
                <Menu.Item key="env" icon={<ToolOutlined />}>
                  <Link to="/environment">Environments</Link>
                </Menu.Item>
                <Menu.Item key="org" icon={<GlobalOutlined />}>
                  <Link to="/organization">Organizations</Link>
                </Menu.Item>
                <Menu.Item key="patient" icon={<UserOutlined />}>
                  <Link to="/patients">Patients</Link>
                </Menu.Item>
                <Menu.Item key="user" icon={<ForkOutlined />}>
                  <Link to="/user">Users</Link>
                </Menu.Item>
                <Menu.Item key="test-cases" icon={<AuditOutlined />}>
                  <Link to="/test-cases">Test Cases</Link>
                </Menu.Item>
                <Menu.Item key="test-runs" icon={<AuditOutlined />}>
                  <Link to="/test-runs">Test Runs</Link>
                </Menu.Item>
              </Menu>
            </Layout.Sider>
            <Layout>
              <Layout.Content style={{ height: '100%', borderRight: 0 }}>
                <Switch>
                  <Route path='/environment'>
                    <Environments />
                  </Route>
                  <Route path='/organization'>
                    <Organizations />
                  </Route>
                  <Route path='/patients'>
                    <Patients />
                  </Route>
                  <Route path='/user'>
                    <Users />
                  </Route>
                  <Route path='/test-cases'>
                    <TestCases />
                  </Route>
                  <Route path='/test-runs'>
                    <TestRuns />
                  </Route>
                  <Route path='/test-case/:id'>
                    <TestCaseView />
                  </Route>
                  <Route path='/test-run/:id'>
                    <TestRunView />
                  </Route>
                  <Route path='/patient/:id'>
                    <PatientView />
                  </Route>
                  <Route path='/'>
                    <ApiUsers />
                  </Route>
                </Switch>
              </Layout.Content>
            </Layout>
          </Layout>
        </Layout>
      </DataContext.Provider>
    </Router>
  );
}

export default BaseApp;