import firebase from '../../firebase';
import { ENVIRONMENTS } from './collections';

export const remove = ({ id, status }) => firebase
  .firestore()
  .collection(ENVIRONMENTS)
  .doc(id)
  .update({
    active: !status.active,
  });

export const add = ({ name }) => firebase
  .firestore()
  .collection(ENVIRONMENTS)
  .add({
    name,
    active: true,
  });

export const edit = ({ id, name }) => firebase
  .firestore()
  .collection(ENVIRONMENTS)
  .doc(id)
  .update({
    ...(name & { name }),
  });

const organizations = {
  remove,
  add,
  edit,
};

export default organizations;
