import React, { useContext, useState } from 'react';
import { PageHeader, Descriptions, Typography, Spin } from 'antd';
import { useCollection, useElement } from '../../utils/useFirebase';
import { useParams } from 'react-router-dom';
import { find, get, capitalize } from 'lodash';
import DataContext from '../../utils/DataContext';
import {
  arrayDTO,
} from '../../utils';
import ActionButtons, { TOKEN, DELETE, EDIT, BOOK, CLONE } from '../../components/action-buttons';
import { patient as patientActions } from '../../utils/firebase';
import { dataSourceDTO, formMapper } from '../patients/utils';
import FormModal from '../../components/form-modal';
import PatientForm from '../patients/PatientForm';

const booleanToText = (value) => value ? 'YES' : 'NO';

const PatientView = () => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [patientState, setPatient] = useState(null);
  const {
    organizations,
    environments,
  } = useContext(DataContext);
  const appUsers = useCollection('app-users');
  const appUsersMap = arrayDTO(appUsers);
  const organizationMap = arrayDTO(organizations);
  const environmentMap = arrayDTO(environments);

  const patient = useElement('patients', id);

  if (!(patient && organizations && environments && organizations.length > 0 && environments.length > 0)) return <Spin></Spin>;

  const name = get(patient, 'authData.patient.name');
  const org = get(organizationMap, patient.org);
  const env = find(org.environments, { 'environment': patient.env });

  const patientDTO = dataSourceDTO(patient, {
    environments: environmentMap,
    organizations: organizationMap,
    appUsers: appUsersMap,
  });

  const { action = 'add', ...rawPatient } = patientState || {};
  const bookPerson = get(appUsersMap, get(patient, 'availableInfo.user'));
  const actions = [
    EDIT,
    BOOK,
    CLONE,
    DELETE,
    ...(patient.fake ? [TOKEN] : [])
  ];

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={`${name}`}
        subTitle={patient.id}
      >
        <div style={{ display: 'flex' }}>
          <div className="main">
            <Descriptions
              size="small"
              column={2}
            >
              <Descriptions.Item label="Environment">{get(env, 'envName', capitalize(org.name))}</Descriptions.Item>
              <Descriptions.Item label="Name">{get(patient, 'authData.patient.name', 'N/A')}</Descriptions.Item>
              <Descriptions.Item label="Password">{get(patient, 'authData.patient.password', 'N/A')}</Descriptions.Item>
              <Descriptions.Item label="Type">{get(patient, 'authData.patient.kind', 'N/A')}</Descriptions.Item>
              <Descriptions.Item label="Is Active?">{booleanToText(patient.active)}</Descriptions.Item>
              <Descriptions.Item label="Is Avaliable?">{bookPerson ? `Booked by ${bookPerson.displayName}` : booleanToText(patient.available)}</Descriptions.Item>
              <Descriptions.Item label="Is Mock Patient?">{booleanToText(patient.mock)}</Descriptions.Item>
              <Descriptions.Item label="Is Fake Patient?">{booleanToText(patient.fake)}</Descriptions.Item>
              <Descriptions.Item label="Auth Code">{get(patient, 'authData.code', 'N/A')}</Descriptions.Item>
              <Descriptions.Item label="Auth Data">{get(patient, 'authData.data', 'N/A')}</Descriptions.Item>
            </Descriptions>
          </div>
        </div>
        <Typography.Title level={4}>
          Actions
        </Typography.Title>
        <ActionButtons
          setShowModal={setShowModal}
          setData={setPatient}
          data={patientDTO}
          collection={patientActions}
          extended={true}
          actions={actions}
          organizations={organizationMap}
        />
      </PageHeader>
      {patient && <FormModal
        organizations={organizations}
        environments={environments}
        showModal={showModal}
        setShowModal={setShowModal}
        data={rawPatient}
        setData={setPatient}
        onSave={patientActions[action]}
        sectionName="Patients"
        id="patients-form-modal"
        initialValuesMapper={formMapper}
        Form={PatientForm}
      />}
    </>
  );
};

export default PatientView;
