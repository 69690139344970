import React, { useState } from 'react';
import { Tag, Tooltip, Button, Modal, Typography } from 'antd';
import { InfoOutlined } from '@ant-design/icons';

const TestStatusBadge = ({
  result,
  failureDetails = [],
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
    {result
      ? (
        <Tag color="green">PASSED</Tag>
      )
      : (
        <>
          <Tag color="red">FAILED</Tag>
          <Tooltip title="get more info">
            <Button
              icon={<InfoOutlined />}
              size="small"
              shape="circle"
              danger
              onClick={() => setShowModal(true)}
            />
          </Tooltip>
          <Modal
            title="Errors log"
            width="90vw"
            visible={showModal}
            onOk={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
          >
            <Typography.Title>Message</Typography.Title>
            <pre>
              {failureDetails[0].message}
            </pre>
            <Typography.Title>Stack</Typography.Title>
            <pre>
              {failureDetails[0].stack}
            </pre>
          </Modal>
        </>
      )
    }
    </>
  );
};

export default TestStatusBadge;