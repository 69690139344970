import firebase from '../../firebase';
import { TEST_CASES } from './collections';

export const remove = ({ id, status }) => firebase
  .firestore()
  .collection(TEST_CASES)
  .doc(id)
  .update({
    active: !status.active,
  });

export const add = ({
  title, description, type = 'e2e', testIds = [],
}) => firebase
  .firestore()
  .collection(TEST_CASES)
  .add({
    title,
    description,
    testIds,
    type,
    active: true,
  });

export const edit = ({
  id, title, description, testIds, type,
}) => firebase
  .firestore()
  .collection(TEST_CASES)
  .doc(id)
  .update({
    ...(title && { title }),
    ...(description && { description }),
    ...(testIds && { testIds }),
    ...(type && { type }),
  });

const organizations = {
  remove,
  add,
  edit,
};

export default organizations;
