import React, { useContext } from 'react';
import { get } from 'lodash';
import { PageHeader, Descriptions, Statistic, Tabs, Table } from 'antd';
import { Link } from 'react-router-dom';
import { useCollection, useElement } from '../../utils/useFirebase';
import { useParams } from 'react-router-dom';
import { formatDistance, format } from 'date-fns';
import DataContext from '../../utils/DataContext';
import { arrayDTO, RepoByDefault } from '../../utils';
import TestStatusBadge from '../../components/test-status-badge';

const TestRunView = () => {
  const { id } = useParams();
  const testRun = useElement('test-runs', id);
  const {
    organizations,
    environments,
  } = useContext(DataContext);
  const apiUsers = useCollection('api-users');
  const appUsers = useCollection('app-users');
  const testCases = useCollection('test-cases');
  const environmentMap = arrayDTO(environments);
  const organizationMap = arrayDTO(organizations);
  const testCasesMap = arrayDTO(testCases);
  const apiUsersMap = arrayDTO(apiUsers);
  const appUsersMap = arrayDTO(appUsers);
  const {
    environment: environmentStr,
    organization: organizationStr,
    testResults,
    apiKey,
    prInfo,
  } = testRun || {};
  const owner = get(apiUsersMap, `${apiKey}.owner`);
  const user = get(appUsersMap, `${owner}`);
  const environment = get(environmentMap, environmentStr);
  const organization = get(organizationMap, organizationStr);
  const testResultsPassed = testResults && testResults.filter(({ result }) => result);
  const startDate = get(testRun, 'startDate') && new Date(testRun.startDate.seconds * 1000);
  const endDate = get(testRun, 'endDate') && new Date(testRun.endDate.seconds * 1000);
  const dataSource = testResults && testResults.map(({ duration, result, testId, failureDetails }) => ({
    ...testCasesMap[testId],
    result,
    duration,
    failureDetails,
  }));
  const envNameDefault = environment && organization ? `${environment.name} (${organization.name})`.toUpperCase() : '';
  const { environments: envs = [] } = organization || {};
  const { envName = envNameDefault } = envs.find(({ environment: id }) => id === environment.id) || {};

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={`Test Run ${id}`}
        subTitle={user && user.id}
        footer={(
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Test Cases" key="1" />
          </Tabs>
        )}
      >
        <div style={{ display: 'flex' }}>
          <div className="main">
            <Descriptions size="small" column={2}>
              <Descriptions.Item label="Created">{get(user,'displayName')}</Descriptions.Item>
              <Descriptions.Item label="Start Time">{startDate && format(startDate, 'yyyy-MM-dd hh:mm:ss aaaa')}</Descriptions.Item>
              <Descriptions.Item label="Duration">{startDate && endDate && formatDistance(startDate, endDate)}</Descriptions.Item>
              <Descriptions.Item label="End Time">{endDate && format(endDate, 'yyyy-MM-dd hh:mm:ss aaaa')}</Descriptions.Item>
              <Descriptions.Item label="Environment">{envName.toUpperCase()}</Descriptions.Item>
              {prInfo && (
                <>
                  <Descriptions.Item label="Repository">
                    <a
                      href={`https://github.com/pagerinc/${prInfo.repoName || RepoByDefault}`}
                      target="_blank"
                      rel="noreferrer"
                    > {prInfo.repoName || RepoByDefault}</a>
                  </Descriptions.Item>
                  <Descriptions.Item label="Branch">
                    <a
                      href={`https://github.com/pagerinc/${prInfo.repoName || RepoByDefault}/tree/${prInfo.headBranch}`}
                      target="_blank"
                      rel="noreferrer"
                    >{prInfo.headBranch}</a>
                  </Descriptions.Item>
                  <Descriptions.Item label="PR Number">
                    <a
                        href={`https://github.com/pagerinc/${prInfo.repoName || RepoByDefault}/pull/${prInfo.prNumber}`}
                        target="_blank"
                        rel="noreferrer"
                      >#{prInfo.prNumber}</a>
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
          </div>
          <div className="extra">
            <div
              style={{
                display: 'flex',
                width: 'max-content',
                justifyContent: 'flex-end',
              }}
            >
              <Statistic
                title="Status"
                value={endDate ? 'Finished' : 'In Progress'}
                style={{
                  marginRight: 32,
                }}
              />
              <Statistic
                title="Test Cases"
                suffix={`/ ${testResults ? testResults.length : 0}`}
                value={testResultsPassed ? testResultsPassed.length : 0}
              />
            </div>
          </div>
        </div>
      </PageHeader>
      <Table
        columns={[
          {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
            render: (id) =><Link to={`/test-case/${id}`}>{id}</Link>
          },
          {
            title: 'Title',
            key: 'title',
            dataIndex: 'title',
          },
          {
            title: 'Duration',
            key: 'duration',
            dataIndex: 'duration',
            render: (duration) => formatDistance(new Date(0), new Date(duration), { includeSeconds: true }),
          },
          {
            title: 'Result',
            key: 'result',
            render: TestStatusBadge,
          }
        ]}
        dataSource={dataSource}
        pagination={{ pageSize: 20 }}
      />
    </>
  );
};

export default TestRunView;
