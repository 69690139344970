import { get } from 'lodash';

export const arrayDTO = (array, field = 'id') => array.reduce((acc, value) => ({
  ...acc,
  [value[field]]: value,
}), {});

export const filterDTO = (field = 'name') => (obj) => ({ text: get(obj, field), value: get(obj, field) });

export const alphabeticalOrder = (field) => (a, b) => {
  if (a[field] < b[field]) return -1;
  if (a[field] > b[field]) return 1;

  return 0;
};

export const valueEquality = (field) => (value, record) => value.toUpperCase() === get(record, field, '').toUpperCase();

export const statusFilterValues = [
  {
    text: 'Active',
    value: 'active',
    cb: ({ active, availableInfo, isWorking }) => active && !availableInfo && isWorking,
  },
  {
    text: 'Available',
    value: 'available',
    cb: ({ available, isWorking, availableInfo }) => available && isWorking && availableInfo,
  },
  {
    text: 'Broken',
    value: 'broken',
    cb: ({ isWorking }) => !isWorking,
  },
  {
    text: 'Disabled',
    value: 'disabled',
    cb: ({ active }) => !active,
  },
  {
    text: 'Unavailable',
    value: 'unavailable',
    cb: ({ availableInfo }) => availableInfo,
  },
];

export const statusEquality = (status, patient) => {
  const { cb } = statusFilterValues.find(({ value }) => value === status);

  return cb(patient.status);
};

export const getEnvironmentValue = ({ env, org }) => {
  if (!org || !env) return '';
  const { envName } = org.environments.find(({ environment: id }) => id === env.id) || {};

  if (envName) return envName.toUpperCase();

  return `${env.name} (${org.name})`.toUpperCase();
};


export const RepoByDefault = 'playwright-automation';
