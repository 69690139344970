import React from 'react';
import { Form, Input, Select } from 'antd';

const PatientForm = ({
  id = 'patient-form',
  form,
  onFinish,
  organizations,
  environments,
  initialValues,
}) => (
  <Form
    initialValues={initialValues}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    form={form}
    id={id}
    name={id}
    onFinish={onFinish}
  >
    <Form.Item
      label="Organization"
      name="org"
      rules={[
        {
          required: true,
          message: 'Please select the organization of your patient'
        }
      ]}
    >
      <Select>
        {organizations.map(({ id, name }) => <Select.Option value={id} key={id}>{name}</Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item
      label="Environment"
      name="env"
      rules={[
        {
          required: true,
          message: 'Please select the environment of your patient'
        }
      ]}
    >
      <Select>
        {environments.map(({ id, name }) => <Select.Option value={id} key={id}>{name}</Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item
      label="Name"
      name="name"
      rules={[
        {
          required: true,
          message: 'Please input the name of your user'
        }
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Password"
      name="password"
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Kind"
      name="kind"
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Auth Code"
      name="code"
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Auth Data"
      name="data"
    >
      <Input.TextArea />
    </Form.Item>
  </Form>
);

export default PatientForm;