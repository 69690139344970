import React, { useState } from 'react';
import { Button, Modal, Form } from 'antd';

const FormModal = ({
  organizations,
  environments,
  showModal,
  setShowModal,
  data,
  setData,
  onSave,
  sectionName,
  apiKeys,
  id = 'form-modal',
  initialValuesMapper = (data) => data,
  Form: CustomForm,
  footer = ({ loading }) => [
    <Button key="submit" form={id} type="primary" htmlType="submit" loading={loading}>
      Submit
    </Button>
  ],
  onFinish,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const initialValues = data && initialValuesMapper(data);
  const closeModal = () => {
    if (setData) setData(null);
  
    setShowModal(false);
    form.resetFields();
  };

  const onFinishFx = async (values) => {
    setLoading(true);
    await onSave({
      ...(data && { id: data.id }),
      ...values,
    });
    setLoading(false);
    closeModal();
  }

  return (
    <Modal
      title={`Create new ${sectionName}`}
      visible={showModal}
      onCancel={closeModal}
      footer={footer({ loading })}
    >
      <CustomForm
        form={form}
        id={id}
        onFinish={onFinish ? onFinish : onFinishFx}
        organizations={organizations}
        environments={environments}
        apiKeys={apiKeys}
        initialValues={initialValues}
      />
    </Modal>
  );
};

export default FormModal;
