import React, { useState } from 'react';
import { Form, Input, Radio, Select } from 'antd';
import firebase from '../../firebase';

const FakePatientForm = ({
  id = 'fake-patient-form',
  form,
  onFinish,
  organizations,
  initialValues,
}) => {
  const [personas, setPersonas] = useState([]);
  const [persona, setPersona] = useState([]);
  const getPersonas = async ({ org }) => {
    const ui = firebase.functions().httpsCallable('uiFunctions');

    const { data } = await ui({
      method: 'getPersonasV2',
      params: { org },
    });

    setPersonas(data);
  };

  return (
    <Form
      initialValues={initialValues}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      id={id}
      name={id}
      form={form}
      onFinish={onFinish}
      onFieldsChange={(...args) => {
        setPersona(form.getFieldValue('persona'));
      }}
    >
      <Form.Item
        label="Organization"
        name="org"
        rules={[
          {
            required: true,
            message: 'Please select the organization of your patient'
          }
        ]}
      >
        <Select>
          {organizations
            .filter(({ canCreateFakePatients }) => canCreateFakePatients)
            .map(({ id, name }) => <Select.Option value={id} key={id}>{name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item
        label="Persona"
        name="persona"
        rules={[
          {
            required: true,
            message: 'Please select the persona of your patient'
          }
        ]}
        shouldUpdate={(prev, value) => {
          if (prev.org === value.org) return;

          getPersonas({ org: value.org });
        }}
      >
        {personas.length > 0
        ? (
          <Radio.Group>
            {personas.map(({ key, details }) => (
              <Radio key={key} value={key}>
                {details.name}
              </Radio>
            ))}
          </Radio.Group>
        )
        : (
          <span>Please select first an organization</span>
        )}
      </Form.Item>
      {persona === 'CustomName' && <Form.Item
        label="Custom name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please type the name of your patient (separated by space)'
          }
        ]}
      >
        <Input />
      </Form.Item>}
    </Form>
  );
}

export default FakePatientForm;