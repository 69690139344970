export const PATIENTS = 'patients';
export const USERS = 'users';
export const ORGANIZATIONS = 'organizations';
export const ENVIRONMENTS = 'environments';
export const APP_USERS = 'app-users';
export const API_USERS = 'api-users';
export const TEST_CASES = 'test-cases';
export const TEST_RUNS = 'test-runs';

const collections = {
  PATIENTS,
  USERS,
  ORGANIZATIONS,
  ENVIRONMENTS,
  APP_USERS,
  API_USERS,
  TEST_CASES,
  TEST_RUNS,
};

export default collections;
