import React, { useState } from 'react';
import { Button, Row, Col, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const DefaultPageHeader = ({
  Modal,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        size="large"
        shape="circle"
        onClick={() => setShowModal(true)}
      />
      {Modal && (
        <Modal showModal={showModal} setShowModal={setShowModal} />
      )}
    </>
  );
}

const PageHeader = ({
  sectionName,
  Modal,
  ButtonsComponent,
}) => {
  return (
    <Row align="middle" style={{ margin: 20}}>
      <Col flex={1}>
        <Typography.Title style={{ margin: 0 }}>{sectionName}</Typography.Title>
      </Col>
      <Col>
        {ButtonsComponent
          ? <ButtonsComponent />
          : <DefaultPageHeader Modal={Modal} />}
      </Col>
    </Row>
  );
};

export default PageHeader;