import StatusBadge from '../../components/status-badge';
import ActionButtons, { EDIT, DELETE } from '../../components/action-buttons';
import { alphabeticalOrder } from '../../utils';
import { environment } from '../../utils/firebase';

export const dataSourceDTO = (environment) => ({
  raw: environment,
  id: environment.id,
  name: environment.name,
  status: {
    active: environment.active,
  },
});

export const getColumns = ({
  setEnvironment,
  setShowModal,
}) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: alphabeticalOrder('name'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (props) => <StatusBadge {...props} type="test-case"></StatusBadge>,
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (data) => (
      <ActionButtons
        setShowModal={setShowModal}
        setData={setEnvironment}
        data={data}
        collection={environment}
        actions={[EDIT, DELETE]}
      />
    ),
  }
];

export const formMapper = (environment) => ({
  name: environment.name,
  environments: environment.environments,
});
