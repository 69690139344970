import firebase from '../../firebase';
import { ORGANIZATIONS } from './collections';

export const remove = ({ id, status }) => firebase
  .firestore()
  .collection(ORGANIZATIONS)
  .doc(id)
  .update({
    active: !status.active,
  });

export const add = ({ name, environments = [] }) => firebase
  .firestore()
  .collection(ORGANIZATIONS)
  .add({
    name,
    environments,
    active: true,
  });

export const edit = ({ id, name, environments }) => firebase
  .firestore()
  .collection(ORGANIZATIONS)
  .doc(id)
  .update({
    ...(name & { name }),
    ...(environments & { environments }),
  });

const organizations = {
  remove,
  add,
  edit,
};

export default organizations;
