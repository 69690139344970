import React, { useContext, useState } from 'react';
import { Table } from 'antd';
import PageHeader from '../../components/page-header';
import {
  dataSourceDTO,
  getColumns,
  formMapper,
} from './utils';
import { environment as environmentActions } from '../../utils/firebase';
import EnvironmentForm from './EnvironmentForm';
import FormModal from '../../components/form-modal';
import DataContext from '../../utils/DataContext';

const Environments = () => {
  const { environments } = useContext(DataContext);

  const [showModal, setShowModal] = useState(false);
  const [environment, setEnvironment] = useState(null);
  const dataSource = environments.map(environment => dataSourceDTO(environment));
  const { action = 'add', ...rawEnvironment } = environment || {};

  return (
    <>
      <PageHeader
        environments={environments}
        firebaseAction={environmentActions}
        sectionName="Environments"
        Modal={({ showModal, setShowModal }) => (
          <FormModal
            environments={environments}
            showModal={showModal}
            setShowModal={setShowModal}
            onSave={environmentActions.add}
            sectionName="Environments"
            id="environments-form"
            Form={EnvironmentForm}
          />
        )}
      />
      <Table
        columns={getColumns({ environments, setEnvironment, setShowModal })}
        dataSource={dataSource}
        pagination={{ pageSize: 20 }}
      />
      {environment && <FormModal
        environments={environments}
        showModal={showModal}
        setShowModal={setShowModal}
        data={rawEnvironment}
        setData={setEnvironment}
        onSave={environmentActions[action]}
        sectionName="Environments"
        id="environments-form-modal"
        initialValuesMapper={formMapper}
        Form={EnvironmentForm}
      />}
    </>
  );
};

export default Environments;
