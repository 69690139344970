import { Link } from 'react-router-dom';
import StatusBadge from '../../components/status-badge';
import ActionButtons, { EDIT, DELETE } from '../../components/action-buttons';
import { alphabeticalOrder } from '../../utils';
import { testCases } from '../../utils/firebase';

export const dataSourceDTO = (testCases) => ({
  raw: testCases,
  id: testCases.id,
  title: testCases.title,
  type: testCases.type,
  status: {
    active: testCases.active,
  },
});

export const getColumns = ({
  setTestCase,
  setShowModal,
}) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (id) => <Link to={`/test-case/${id}`}>{id}</Link>,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    sorter: alphabeticalOrder('title'),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: alphabeticalOrder('type'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => (
      <StatusBadge {...status} type="test-case" />
    ),
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (data) => (
      <ActionButtons
        setShowModal={setShowModal}
        setData={setTestCase}
        data={data}
        collection={testCases}
        actions={[EDIT, DELETE]}
      />
    ),
  }
];

export const formMapper = (testCases) => ({
  title: testCases.title,
  description: testCases.description,
  type: testCases.type,
  testIds: testCases.testIds,
});
