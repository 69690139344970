import React from 'react';
import { Button, Layout, Card, Row, Col } from 'antd';
import './App.less';
import firebase from 'firebase/app';
import 'firebase/auth';
import withFirebaseAuth from 'react-with-firebase-auth';
import firebaseApp from './firebase';
import BaseApp from './pages';
import Logo from './components/logo';

const firebaseAppAuth = firebaseApp.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};
providers.googleProvider.setCustomParameters({
  hd: 'pager.com',
  scope: ['cloud-platform']
});
providers.googleProvider.addScope('https://www.googleapis.com/auth/cloud-platform');
const createComponentWithAuth = withFirebaseAuth({
  providers,
  firebaseAppAuth,
});

const App = ({
  signInWithGoogle,
  signOut,
  user,
  loading,
}) => (
  <>
    {
      user
        ? <BaseApp user={user} firebaseAppAuth={firebaseAppAuth} signOut={signOut} providers={providers} />
        : (
          <Layout>
            <Layout.Content>
              <Row align="middle" gutter={16} style={{ height: '100vh' }}>
                <Col offset={10} span={5}>
                  <Card
                    cover={<Logo style={{ padding: 10 }} />}
                    style={{ textAlign: 'center' }}
                  >
                    {!user && (
                      <Button loading={loading} type="primary" onClick={signInWithGoogle}>Sign in with Google</Button>
                    )}
                  </Card>
                </Col>
              </Row>
            </Layout.Content>
          </Layout>
        )
    }
  </>
);

export default createComponentWithAuth(App);
