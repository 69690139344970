import firebase from 'firebase/app';
import firebaseConfig from './api-firebase.json';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/functions';

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.functions();

export default firebase;
