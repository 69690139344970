import StatusBadge from '../../components/status-badge';
import ActionButtons, { EDIT, DELETE } from '../../components/action-buttons';
import { alphabeticalOrder } from '../../utils';
import { organization } from '../../utils/firebase';

export const dataSourceDTO = (organization) => ({
  raw: organization,
  id: organization.id,
  name: organization.name,
  status: {
    active: organization.active,
  },
});

export const getColumns = ({
  setOrganization,
  setShowModal,
}) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: alphabeticalOrder('name'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (props) => <StatusBadge {...props} type="test-case"></StatusBadge>,
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (data) => (
      <ActionButtons
        setShowModal={setShowModal}
        setData={setOrganization}
        data={data}
        collection={organization}
        actions={[EDIT, DELETE]}
      />
    ),
  }
];

export const formMapper = (organization) => ({
  name: organization.name,
  environments: organization.environments,
});
