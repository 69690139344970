import React, { useContext, useState } from 'react';
import { Tooltip, Button, Space, notification, Popover } from 'antd';
import {
  CopyOutlined,
  EditOutlined,
  UserDeleteOutlined,
  AimOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { get } from 'lodash';
import DataContext from '../../utils/DataContext';
import firebase from '../../firebase';

export const CLONE = 'clone';
export const BOOK = 'book';
export const DELETE = 'delete';
export const EDIT = 'edit';
export const TOKEN = 'token';

const ActionButtons = ({
  data,
  setShowModal,
  setData,
  collection,
  actions = [EDIT, BOOK, CLONE, DELETE],
  organizations,
}) => {
  const org = get(data, 'raw.org');
  const [showPopover, setShowPopover] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { user: appUser } = useContext(DataContext);
  const handleBook = () => collection.book(data, appUser);
  const handleDelete = () => collection.remove(data);
  const handleClone = () => {
    setData({
      ...data.raw,
      action: 'add',
    });
    setShowModal(true);
  };
  const handleEdit = () => {
    setData({
      ...data.raw,
      action: 'edit',
    });
    setShowModal(true);
  };

  const { isWorking = true } = data.status || {};

  const onCopy = ({ name }) => api.info({
    message: `You booked ${name}`,
    description: 'The token was copied in your clipboard',
    placement: 'topRight',
  });

  const onTokenGenerated = async ({ data, env, org }) => {
    const ui = firebase.functions().httpsCallable('uiFunctions');

    const { data: status } = await ui({
      method: 'getToken',
      params: { org: org.id, env: env.environment, patient: data.raw },
    });

    api.info({
      message: `You generated token for ${data.name}`,
      description: `The token is ${status.token}`,
      placement: 'topRight',
    });
  }

  return (
    <>
      {contextHolder}
      <Space size="small">
        {actions.includes(EDIT) && (
          <Tooltip title="edit">
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={handleEdit} />
          </Tooltip>
        )}
        {actions.includes(BOOK) && (
          data.status.token && data.status.available
          ? (
            <CopyToClipboard text={data.status.token} onCopy={() => onCopy(data)}>
              <Tooltip title="book">
                <Button
                  type={!data.status.available && 'primary'}
                  shape="circle"
                  disabled={!isWorking}
                  icon={<AimOutlined />}
                  onClick={handleBook} />
              </Tooltip>
            </CopyToClipboard>
          )
          : (
            <Tooltip title="book">
              <Button
                type={!data.status.available && 'primary'}
                shape="circle"
                disabled={!isWorking}
                icon={<AimOutlined />}
                onClick={handleBook} />
            </Tooltip>
          )
        )}
        {actions.includes(CLONE) && (
          <Tooltip title="clone">
            <Button
              shape="circle"
              icon={<CopyOutlined />}
              onClick={handleClone} />
          </Tooltip>
        )}
        {actions.includes(TOKEN) && (
          <Tooltip title="token">
            <Popover
              content={
                <>
                  {organizations[org].environments
                    .filter(({ canUseFakePatients }) => Boolean(canUseFakePatients))
                    .map((env) => (
                      <div key={env.envName}>
                        <Button type="link" onClick={() => onTokenGenerated({ env, org: organizations[org], data })}>{env.envName}</Button>
                      </div>
                    ))}
                </>
              }
              title="Get token for:"
              trigger="click"
              visible={showPopover}
              placement="bottom"
              onVisibleChange={(visible) => setShowPopover(visible)}
            >
              <Button
                shape="circle"
                icon={<LinkOutlined />} />
            </Popover>
          </Tooltip>
        )}
        {actions.includes(DELETE) && (
          <Tooltip title="delete">
            <Button
              type={data.status.active && 'danger'}
              shape="circle"
              icon={<UserDeleteOutlined />}
              onClick={handleDelete} />
          </Tooltip>
        )}
      </Space>
    </>
  );
}

export default ActionButtons;