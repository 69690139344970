import React, { useContext, useState } from 'react';
import { Table } from 'antd';
import PageHeader from '../../components/page-header';
import { arrayDTO } from '../../utils';
import {
  dataSourceDTO,
  getColumns,
  formMapper,
} from './utils';
import { organization as organizationActions } from '../../utils/firebase';
import OrganizationForm from './OrganizationForm';
import FormModal from '../../components/form-modal';
import DataContext from '../../utils/DataContext';

const Organizations = () => {
  const {
    organizations,
    environments,
  } = useContext(DataContext);

  const [showModal, setShowModal] = useState(false);
  const [organization, setOrganization] = useState(null);
  const environmentMap = arrayDTO(environments);
  const dataSource = organizations.map(organization => dataSourceDTO(organization, {
    environments: environmentMap,
  }));
  const { action = 'add', ...rawOrganization } = organization || {};

  return (
    <>
      <PageHeader
        organizations={organizations}
        environments={environments}
        firebaseAction={organizationActions}
        sectionName="Organizations"
        Modal={({ showModal, setShowModal }) => (
          <FormModal
            organizations={organizations}
            environments={environments}
            showModal={showModal}
            setShowModal={setShowModal}
            onSave={organizationActions.add}
            sectionName="Organizations"
            id="organizations-form"
            Form={OrganizationForm}
          />
        )}
      />
      <Table
        columns={getColumns({ environments, organizations, setOrganization, setShowModal })}
        dataSource={dataSource}
        pagination={{ pageSize: 20 }}
      />
      {organization && <FormModal
        organizations={organizations}
        environments={environments}
        showModal={showModal}
        setShowModal={setShowModal}
        data={rawOrganization}
        setData={setOrganization}
        onSave={organizationActions[action]}
        sectionName="Organizations"
        id="organizations-form-modal"
        initialValuesMapper={formMapper}
        Form={OrganizationForm}
      />}
    </>
  );
};

export default Organizations;
