import React, { useContext, useMemo } from 'react';
import { PageHeader, Descriptions, Statistic, Tabs, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useCollection, useElement } from '../../utils/useFirebase';
import { useParams } from 'react-router-dom';
import { formatDistance, format } from 'date-fns';
import DataContext from '../../utils/DataContext';
import {
  arrayDTO,
  getEnvironmentValue,
  filterDTO,
  valueEquality,
} from '../../utils';
import TestStatusBadge from '../../components/test-status-badge';
import DescriptionsItem from 'antd/lib/descriptions/Item';

const TestRunView = () => {
  const { id } = useParams();
  const {
    organizations,
    environments,
  } = useContext(DataContext);
  const apiUsers = useCollection('api-users');
  const appUsers = useCollection('app-users');
  const apiUsersMap = arrayDTO(apiUsers);
  const appUsersMap = arrayDTO(appUsers);
  const organizationMap = arrayDTO(organizations);
  const environmentMap = arrayDTO(environments);
  const testCase = useElement('test-cases', id);
  const query = useMemo(() => ({
    query: ['testIds', 'array-contains', id],
  }), [id]);
  const testRuns = useCollection('test-runs', query);

  const testRunsComputed = testCase && testRuns && environmentMap && organizationMap && apiUsersMap && testRuns.map(({
    environment,
    organization,
    id,
    startDate,
    apiKey,
    testResults,
  }) => ({
    environment: getEnvironmentValue({
      env: environmentMap[environment],
      org: organizationMap[organization],
    }),
    id,
    user: apiUsersMap[apiKey] && apiUsersMap[apiKey].owner && appUsersMap[apiUsersMap[apiKey].owner],
    startDate: new Date(startDate.seconds * 1000),
    testResult: testResults.find(({ testId }) => testId === testCase.id),
  }));
  const testRunsComputedPassed = testRunsComputed && testRunsComputed.filter(({ testResult }) => testResult.result);

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={`Test Case: ${testCase && testCase.title}`}
        subTitle={testCase && testCase.id}
        footer={(
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Test Runs" key="1" />
          </Tabs>
        )}
      >
        <div style={{ display: 'flex' }}>
          <div className="main">
            <Descriptions
              size="small"
              column={2}
            >
              <Descriptions.Item label="Type">{testCase && testCase.type && testCase.type.toUpperCase()}</Descriptions.Item>
              {testCase && testCase.testIds.map(({ id, organization }) => Number.isFinite(+id)
              ? (
                  <Descriptions.Item
                    key={id}
                    label={`TestRail ID for ${organizationMap[organization] && organizationMap[organization].name}`}
                  >
                    <a
                      href={`https://pager.testrail.io/index.php?/cases/view/${id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {id}
                    </a>
                  </Descriptions.Item>
              )
              : (
                <DescriptionsItem
                  key={id}
                  label={`is this run for ${organizationMap[organization] && organizationMap[organization].name}?`}
                >
                  {id ? 'Yes' : 'No'}
                </DescriptionsItem>
              ))}
            </Descriptions>
          </div>
          <div className="extra">
            <div
              style={{
                display: 'flex',
                width: 'max-content',
                justifyContent: 'flex-end',
              }}
            >
              <Statistic
                title="Test Runs"
                suffix={`/ ${testRunsComputed ? testRunsComputed.length : 0}`}
                value={testRunsComputedPassed ? testRunsComputedPassed.length : 0}
              />
            </div>
          </div>
        </div>
        <Typography.Title level={4}>
          Description
        </Typography.Title>
        <Typography.Paragraph>
          {testCase && testCase.description.split(/\r\n|\r|\n/g).map((line) => <div key={line}>{line}</div>)}
        </Typography.Paragraph>
      </PageHeader>
      <Table
        columns={[
          {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
            render: (id) =><Link to={`/test-run/${id}`}>{id}</Link>,
          },
          {
            title: 'Ran it by',
            key: 'run',
            dataIndex: 'user',
            render: ({ displayName } = {}) => displayName,
          },
          {
            title: 'Date',
            key: 'date',
            dataIndex: 'startDate',
            render: (startDate) => format(startDate, 'MMM do, hh:mm aaaa'),
          },
          {
            title: 'Environment',
            dataIndex: 'environment',
            key: 'environment',
            filters: organizations
              .reduce((acc, { environments }) => acc.concat(environments.filter(({ envName }) => Boolean(envName))), [])
              .map(filterDTO('envName')),
            onFilter: valueEquality('environment'),
          },
          {
            title: 'Duration',
            key: 'duration',
            dataIndex: 'testResult',
            render: ({ duration }) => formatDistance(new Date(0), new Date(duration), { includeSeconds: true }),
          },
          {
            title: 'Result',
            key: 'result',
            dataIndex: 'testResult',
            render: TestStatusBadge,
          }
        ]}
        dataSource={testRunsComputed}
        pagination={{ pageSize: 20 }}
      />
    </>
  );
};

export default TestRunView;
