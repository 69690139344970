import React, { useState } from 'react';
import { Button, Divider, Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { patient as patientActions } from '../../utils/firebase';
import PatientForm from './PatientForm';
import FakePatientForm from './FakePatientForm';
import FormModal from '../../components/form-modal';
import firebase from '../../firebase';
import { withRouter } from 'react-router-dom';

const ButtonsComponent = ({
  organizations,
  environments,
  history,
}) => {
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [showCreateFakePatientModal, setShowCreateFakePatientModal] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const showPatientModalFx = () => {
    setShowPatientModal(true);
    setShowPopover(false);
  };

  const showCreateFakePatientModalFx = () => {
    setShowCreateFakePatientModal(true);
    setShowPopover(false);
  };

  const createFakePatient = async ({ org, persona, name }) => {
    const ui = firebase.functions().httpsCallable('uiFunctions');

    const { data } = await ui({
      method: 'createFakePatient',
      params: { org, persona, name },
    });

    history.push(`/patient/${data.id}`);
  };

  const onFakePatientCreated = ({ org, persona, name, ...moreInfo }) => {
    createFakePatient({ org, persona, name });
  };

  return (
    <>
      <Popover
        content={
          <>
            <div>
              <Button type="link" onClick={showPatientModalFx}>Add existing patient</Button>
            </div>
            <div>
              <Button type="link" onClick={showCreateFakePatientModalFx}>Create fake patient</Button>
            </div>
            <Divider style={{ margin: 6 }}/>
            <Button type="link" onClick={() => setShowPopover(false)}>Close</Button>
          </>
        }
        title="Patient"
        trigger="click"
        visible={showPopover}
        placement="bottomRight"
        onVisibleChange={(visible) => setShowPopover(visible)}
      >
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="large"
          shape="circle"
        />
      </Popover>
      <FormModal
        organizations={organizations}
        environments={environments}
        showModal={showPatientModal}
        setShowModal={setShowPatientModal}
        onSave={patientActions.add}
        sectionName="Patients"
        id="patients-form"
        Form={PatientForm}
      />
      <FormModal
        organizations={organizations}
        environments={environments}
        showModal={showCreateFakePatientModal}
        setShowModal={setShowCreateFakePatientModal}
        onFinish={onFakePatientCreated}
        sectionName="Fake Patient"
        id="fake-patients-form"
        Form={({
          id = 'fake-patient-form',
          form,
          onFinish,
          organizations,
          initialValues,
        }) => (
          <FakePatientForm
            id={id}
            form={form}
            onFinish={onFinish}
            organizations={organizations}
            initialValues={initialValues}
          />
        )}
      />
    </>
  );
};

export default withRouter(ButtonsComponent);