import React from 'react';
import { Form, Input, Select } from 'antd';

const PRODUCTS = [
  { id: 'CC', name: 'Command Center' },
  { id: 'EA', name: 'Enterprise Admin' },
  { id: 'JumpCloud', name: 'JumpCloud'},
]

const UserForm = ({
  id = 'user-form',
  form,
  onFinish,
  organizations,
  environments,
  initialValues,
}) => (
  <Form
    initialValues={initialValues}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    form={form}
    id={id}
    name={id}
    onFinish={onFinish}
  >
    <Form.Item
      label="Organization"
      name="org"
      rules={[
        {
          required: true,
          message: 'Please select the organization of your user'
        }
      ]}
    >
      <Select>
        {organizations.map(({ id, name }) => <Select.Option value={id} key={id}>{name}</Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item
      label="Environment"
      name="env"
      rules={[
        {
          required: true,
          message: 'Please select the environment of your user'
        }
      ]}
    >
      <Select>
        {environments.map(({ id, name }) => <Select.Option value={id} key={id}>{name}</Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item
      label="Product"
      name="product"
      rules={[
        {
          required: true,
          message: 'Please select the product of your user'
        }
      ]}
    >
      <Select>
        {PRODUCTS.map(({ id, name }) => <Select.Option value={id} key={id}>{name}</Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item
      label="Email"
      name="email"
      rules={[
        {
          required: true,
          message: 'Please input the email of your user'
        }
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Password"
      name="password"
      rules={[
        {
          required: true,
          message: 'Please input the password of your user'
        }
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Role"
      name="role"
      rules={[
        {
          required: true,
          message: 'Please input the role of your user'
        }
      ]}
    >
      <Input />
    </Form.Item>
  </Form>
);

export default UserForm;