import React, { useContext, useMemo } from 'react';
import { notification, Table } from 'antd';
import PageHeader from '../../components/page-header';
import { arrayDTO } from '../../utils';
import {
  dataSourceDTO,
  getColumns,
  getTestTags,
  getTriggerName,
} from './utils';
import { useCollection } from '../../utils/useFirebase';
import DataContext from '../../utils/DataContext';
import FormModal from '../../components/form-modal';
import TestRunForm from './TestRunForm';

const TestCases = () => {
  const { firebaseAppAuth, providers, user } = useContext(DataContext);
  const params = useMemo(() => ({
    orderBy: ['startDate', 'desc'],
  }), []);
  const query = useMemo(() => ({
    query: ['owner', '==', user.email],
  }), [user]);
  const apiUsers = useCollection('api-users', query);
  const testRuns = useCollection('test-runs', params);
  const organizations = useCollection('organizations');
  const environments = useCollection('environments');
  const apiKeys = useCollection('api-users');
  const appUsers = useCollection('app-users');
  const environmentMap = arrayDTO(environments);
  const organizationMap = arrayDTO(organizations);
  const apiKeysMap = arrayDTO(apiKeys);
  const appUsersMap = arrayDTO(appUsers);
  const apiKeysComplete = Object.keys(apiKeysMap).reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        ...apiKeysMap[key],
        user: appUsersMap[apiKeysMap[key].owner],
      }
    }
  }, {});

  const dataSource = testRuns.map(testRun => dataSourceDTO(testRun, {
    environments: environmentMap,
    organizations: organizationMap,
    apiKeys: apiKeysComplete,
  }));

  const runGCPTrigger = async ({ org, env, apiKey, product, testType }) => {
    const triggerName = getTriggerName(testType);
    const testTag = getTestTags({ org, product });

    const { credential } = await firebaseAppAuth.signInWithPopup(providers.googleProvider);
    const response = await fetch(
      `https://cloudbuild.googleapis.com/v1/projects/production-197117/triggers/${triggerName}`,
      {
      headers: {
        'Authorization': `Bearer ${credential.accessToken}`, 
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        projectId: 'production-197117',
        substitutions: {
          _ENVIRONMENT: env,
          _ORGANIZATION: org,
          _PRODUCT: product,
          _AUTH_TOKEN: apiKey,
          _TEST_TAG: testTag,
        },
      }),
    });
    const { metadata } = await response.json();

    notification.open({
      key: `open${Date.now()}`,
      message: `You test run started!`,
      description: 'Your test run is running but is going to take a while to see it here in the Automation Admin. If you want to see the progress you can go to the GCP trigger detail',
      btn: (
        <a
          href={metadata.build.logUrl}
          target="_blank"
          rel="noreferrer"
          className="ant-btn ant-btn-primary"
        >
          Go to GCP trigger detail
        </a>
      ),
      onClose: () => {},
    });
  };

  return (
    <>
      <PageHeader
        organizations={organizations}
        sectionName="Test Runs"
        Modal={({ showModal, setShowModal }) => (
          <FormModal
            organizations={organizations}
            environments={environments}
            apiKeys={apiUsers}
            showModal={showModal}
            setShowModal={setShowModal}
            onSave={runGCPTrigger}
            sectionName="Test Run"
            id="test-run-form"
            Form={TestRunForm}
          />
        )}
      />
      <Table
        columns={getColumns({ organizations })}
        dataSource={dataSource}
        pagination={{ pageSize: 20 }}
      />
    </>
  );
};

export default TestCases;
