import React from 'react';
import { Tag, Avatar, Tooltip } from 'antd';

const StatusBadge = ({
  active,
  available,
  availableInfo,
  type = 'patient'
}) => {
  if (available && type === 'patient') return <Tag color="green">AVAILABLE</Tag>
  if (active && type === 'test-case') return <Tag color="green">ACTIVE</Tag>

  return (
    <>
      <Tag color="red">{type === 'patient' ? 'UNAVAILABLE' : 'DISABLED'}</Tag>
      {availableInfo && (
        <Tooltip title={availableInfo && availableInfo.displayName} placement="top">
          <Avatar src={availableInfo && availableInfo.photoURL}/>
        </Tooltip>
      )}
    </>
  )
};

export default StatusBadge;
