import { useState, useEffect } from 'react';
import firebase from '../firebase';

export const useCollection = (collectionName, {
  query,
  orderBy,
  multipleOrderBy,
} = {}) => {
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    let instance = firebase
      .firestore()
      .collection(collectionName);

    if (query) {
      instance = instance.where(...query);
    }

    if (orderBy) {
      instance = instance.orderBy(...orderBy);
    }

    if (multipleOrderBy) {
      multipleOrderBy.forEach((orderBy) => {
        instance = instance.orderBy(...orderBy);
      });
    }

    const unsuscribe = instance
      .onSnapshot((snapshot) => {
        const newValue = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setCollection(newValue);
      });

    return () => unsuscribe();
  }, [collectionName, query, orderBy, multipleOrderBy]);

  return collection;
};

export const useElement = (collectionName, id) => {
  const [element, setElement] = useState(null);

  useEffect(() => {
    const instance = firebase
      .firestore()
      .collection(collectionName)
      .doc(id);

    const unsuscribe = instance
      .onSnapshot((snapshot) => {
        setElement({
          id: snapshot.id,
          ...snapshot.data(),
        });
      });

    return () => unsuscribe();
  }, [collectionName, id]);

  return element;
};

