import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Table } from 'antd';
import firebase from '../../firebase';
import DataContext from '../../utils/DataContext';
import { apiUser as apiUserActions } from '../../utils/firebase';
import PageHeader from '../../components/page-header';
import {
  dataSourceDTO,
  getColumns,
  formMapper,
} from './utils';
import ApiUserForm from './ApiUserForm';
import FormModal from '../../components/form-modal';
import { useCollection } from '../../utils/useFirebase';

export function useApiUsers(user) {
  const [apiUsers, setApiUsers] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('api-users')
      .where('owner', '==', user.email)
      .onSnapshot((snapshot) => {
        const newApiUsers = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setApiUsers(newApiUsers);
      });

    return () => unsubscribe();
  }, [user]);

  return apiUsers;
};

const ApiUsers = () => {
  const { user } = useContext(DataContext);
  const query = useMemo(() => ({
    query: ['owner', '==', user.email],
  }), [user])
  const apiUsers = useCollection('api-users', query);

  const [showModal, setShowModal] = useState(false);
  const [apiUser, setApiUser] = useState(null);
  const dataSource = apiUsers.map(apiUser => dataSourceDTO(apiUser));
  const { action = 'add', ...rawApiUser } = apiUser || {};

  return (
    <>
      <PageHeader
        firebaseAction={apiUserActions}
        sectionName="API keys"
        Modal={({ showModal, setShowModal }) => (
          <FormModal
            showModal={showModal}
            setShowModal={setShowModal}
            onSave={(values) => apiUserActions.add({
              ...values,
              owner: user.email,
            })}
            sectionName="API keys"
            id="api-key-form"
            Form={ApiUserForm}
          />
        )}
      />
      <Table
        columns={getColumns({ setApiUser, setShowModal })}
        dataSource={dataSource}
        pagination={{ pageSize: 20 }}
      />
      {apiUser && <FormModal
        apiUsers={apiUsers}
        showModal={showModal}
        setShowModal={setShowModal}
        data={rawApiUser}
        setData={setApiUser}
        onSave={apiUserActions[action]}
        sectionName="API keys"
        id="api-key-form-modal"
        initialValuesMapper={formMapper}
        Form={ApiUserForm}
      />}
    </>
  );
};

export default ApiUsers;