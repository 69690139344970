import React from 'react';
import { Form, Select } from 'antd';
import { TEST_TYPES } from './utils';

const PRODUCTS = [
  { id: 'CC', name: 'Command Center' },
  { id: 'EA', name: 'Enterprise Admin' },
]

const TestRunForm = ({
  id = 'test-run-form',
  form,
  onFinish,
  organizations,
  environments,
  apiKeys,
  initialValues,
}) => (
  <Form
    initialValues={initialValues}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    form={form}
    id={id}
    name={id}
    onFinish={onFinish}
  >
    <Form.Item
      label="Organization"
      name="org"
      rules={[
        {
          required: true,
          message: 'Please select the organization you want to use for this test run'
        }
      ]}
    >
      <Select>
        {organizations.map(({ id, name }) => <Select.Option value={name.toUpperCase()} key={id}>{name}</Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item
      label="Environment"
      name="env"
      rules={[
        {
          required: true,
          message: 'Please select the environment you want to use for this test run'
        }
      ]}
    >
      <Select>
        {environments.map(({ id, name }) => <Select.Option value={name.toUpperCase()} key={id}>{name}</Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item
      label="Product"
      name="product"
      rules={[
        {
          required: true,
          message: 'Please select the product for this test run'
        }
      ]}
    >
      <Select>
        {PRODUCTS.map(({ id, name }) => <Select.Option value={id} key={id}>{name}</Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item
      label="Test Type"
      name="testType"
      rules={[
        {
          required: true,
          message: 'Please select the test type for this test run.'
        }
      ]}
    >
      <Select>
        {TEST_TYPES.map(({ id, name }) => <Select.Option value={id} key={id}>{name}</Select.Option>)}
      </Select>
    </Form.Item>
    <Form.Item
      label="API Key"
      name="apiKey"
      rules={[
        {
          required: true,
          message: 'Please select the api key you want to use for this test run'
        }
      ]}
    >
      <Select>
        {apiKeys.map(({ id, name }) => <Select.Option value={id} key={id}>{name}</Select.Option>)}
      </Select>
    </Form.Item>
  </Form>
);

export default TestRunForm;