import firebase from '../../firebase';
import { PATIENTS } from './collections';

export const book = ({ id, status }, appUser) => firebase
  .firestore()
  .collection(PATIENTS)
  .doc(id)
  .update({
    available: !status.available,
    availableInfo: status.available
      ? {
        user: appUser.email,
        manual: true,
        bookDate: new Date(),
      }
      : null,
  });

export const remove = ({ id, status }) => firebase
  .firestore()
  .collection(PATIENTS)
  .doc(id)
  .update({
    active: !status.active,
  });

export const add = ({
  code, data, name, password, kind, env, org,
}) => firebase
  .firestore()
  .collection(PATIENTS)
  .add({
    env,
    org,
    authData: {
      ...(code && { code }),
      ...(data && { data }),
      patient: {
        ...(name && { name }),
        ...(password && { password }),
        ...(kind && { kind }),
      },
    },
    active: true,
    available: true,
  });

export const edit = ({
  id, code, data, name, password, kind, env, org,
}) => firebase
  .firestore()
  .collection(PATIENTS)
  .doc(id)
  .update({
    ...(env & { env }),
    ...(org & { org }),
    authData: {
      ...(code && { code }),
      ...(data && { data }),
      patient: {
        ...(name && { name }),
        ...(password && { password }),
        ...(kind && { kind }),
      },
    },
  });

const patient = {
  book,
  remove,
  add,
  edit,
};

export default patient;
