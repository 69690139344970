import React from 'react';
import { Form, Input } from 'antd';

const EnvironmentForm = ({
  id = "environment-form",
  form,
  onFinish,
  initialValues,
}) => (
  <Form
    form={form}
    id={id}
    name={id}
    onFinish={onFinish}
    initialValues={initialValues}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
  >
    <Form.Item
      label="Name"
      name="name"
      rules={[
        {
          required: true,
          message: 'Please input the name of the environment'
        }
      ]}
    >
      <Input />
    </Form.Item>
  </Form>
);

export default EnvironmentForm;
