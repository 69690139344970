import React, { useContext, useMemo, useState } from 'react';
import { Table, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import PageHeader from '../../components/page-header';
import {
  dataSourceDTO,
  getColumns,
  formMapper,
} from './utils';
import { arrayDTO } from '../../utils';
import { user as userActions } from '../../utils/firebase';
import FormModal from '../../components/form-modal';
import UserForm from './UserForm';
import { useCollection } from '../../utils/useFirebase';
import DataContext from '../../utils/DataContext';

const Users = () => {
  const params = useMemo(() => ({
    multipleOrderBy: [
      ['env', 'desc'],
      ['org', 'desc'],
      ['role', 'desc'],
      ['product', 'desc'],
    ],
  }), []);
  const users = useCollection('users', params);
  const {
    organizations,
    environments,
  } = useContext(DataContext);
  const appUsers = useCollection('app-users');

  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const [search, setSearch] = useState("");

  const environmentMap = arrayDTO(environments);
  const organizationMap = arrayDTO(organizations);
  const appUsersMap = arrayDTO(appUsers);
  
  const onSearch = event => setSearch(event.target.value);
  const dataSource = users.filter(usertName => {
    let userNames;
    if (search === "") {
      userNames = usertName;
    } else if (usertName.email.toLowerCase().includes(search.toLowerCase()))  {
      userNames = usertName;
    }
    return userNames
  }).map(user => dataSourceDTO(user, {
    environments: environmentMap,
    organizations: organizationMap,
    appUsers: appUsersMap,
  }));
  const { action = 'add', ...rawUser } = user || {};

  return (
    <>
      <PageHeader
        organizations={organizations}
        environments={environments}
        firebaseAction={userActions}
        sectionName="Users"
        Modal={({ showModal, setShowModal }) => (
          <FormModal
            organizations={organizations}
            environments={environments}
            showModal={showModal}
            setShowModal={setShowModal}
            onSave={userActions.add}
            sectionName="Users"
            id="users-form"
            Form={UserForm}
          />
        )}
      />
      <Input placeholder="Search User" onChange={onSearch}
        suffix={<SearchOutlined />} allowClear style={{ width: 350 }} />
      <Table
        columns={getColumns({ environments, organizations, setUser, setShowModal })}
        dataSource={dataSource}
        pagination={{ pageSize: 20 }}
      />
      {user && <FormModal
        organizations={organizations}
        environments={environments}
        showModal={showModal}
        setShowModal={setShowModal}
        data={rawUser}
        setData={setUser}
        onSave={userActions[action]}
        sectionName="Users"
        id="users-form-modal"
        initialValuesMapper={formMapper}
        Form={UserForm}
      />}
    </>
  );
};

export default Users;
