import React from 'react';
import { Button, Col, Form, Input, Select, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const OrganizationForm = ({
  id = "organization-form",
  form,
  onFinish,
  environments,
  initialValues,
}) =>(
  <Form
    initialValues={initialValues}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    form={form}
    id={id}
    name={id}
    onFinish={onFinish}
  >
    <Form.Item
      label="Name"
      name="name"
      rules={[
        {
          required: true,
          message: 'Please input the name of the organization'
        }
      ]}
    >
      <Input />
    </Form.Item>
    <Form.List name="environments">
      {(fields, { add, remove }) =>(
        <>
          {fields.map(field => (
            <Col key={field.key}>
              <Divider />
              <Form.Item
                {...field}
                name={[field.name, 'environment']}
                fieldKey={[field.fieldKey, 'environment']}
                label="Environment"
                rules={[{ required: true, message: 'Please select an environment' }]}
              >
                <Select>
                  {environments.map(({ id, name }) => (<Select.Option key={id} value={id}>{name}</Select.Option>))}
                </Select>
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, 'envName']}
                fieldKey={[field.fieldKey, 'envName']}
                label="Environment Name"
                rules={[{ required: true, message: "Please input the Command Center 'Environment Name' for this environment" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, 'ccUrl']}
                fieldKey={[field.fieldKey, 'ccUrl']}
                label="CC URL"
                rules={[{ required: true, message: 'Please input the Command Center URL for this environment' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, 'apiUrl']}
                fieldKey={[field.fieldKey, 'apiUrl']}
                label="API URL"
                rules={[{ required: true, message: 'Please input the API URL for this environment' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, 'netlifyEnv']}
                fieldKey={[field.fieldKey, 'netlifyEnv']}
                label="Netlify Env"
                rules={[{ required: true, message: 'Please input the Netlify environment for this environment' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, 'webSdkUrl']}
                fieldKey={[field.fieldKey, 'webSdkUrl']}
                label="WebSDK URL"
                rules={[{ required: true, message: 'Please input the WebSDK URL for this environment' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, 'webSdkEnvironmentName']}
                fieldKey={[field.fieldKey, 'webSdkEnvironmentName']}
                label="WebSDK Env name"
                rules={[{ required: true, message: 'Please input the WebSDK environment name for this environment' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, 'mobileUrl']}
                fieldKey={[field.fieldKey, 'mobileUrl']}
                label="Mobile URL"
                rules={[{ required: true, message: 'Please input the Mobile URL for this environment' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          ))}
          <Divider />
          <Form.Item
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: '60%' }}
              icon={<PlusOutlined />}
            >
              Add Environment
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  </Form>
);

export default OrganizationForm;