import firebase from '../../firebase';
import { API_USERS } from './collections';

export const remove = ({ id, status }) => firebase
  .firestore()
  .collection(API_USERS)
  .doc(id)
  .update({
    active: !status.active,
  });

export const add = ({ name, owner }) => firebase
  .firestore()
  .collection(API_USERS)
  .add({
    name,
    owner,
    active: true,
  });

export const edit = ({ id, name }) => firebase
  .firestore()
  .collection(API_USERS)
  .doc(id)
  .update({
    ...(name & { name }),
  });

const apiUsers = {
  remove,
  add,
  edit,
};

export default apiUsers;
