import React, { useContext, useMemo, useState } from 'react';
import { Table, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import PageHeader from '../../components/page-header';
import { arrayDTO } from '../../utils';
import {
  dataSourceDTO,
  getColumns,
  formMapper,
} from './utils';
import { patient as patientActions } from '../../utils/firebase';
import PatientForm from './PatientForm';
import FormModal from '../../components/form-modal';
import { useCollection } from '../../utils/useFirebase';
import DataContext from '../../utils/DataContext';
import ButtonsComponent from './ButtonsComponent';

const Patients = () => {
  const params = useMemo(() => ({
    multipleOrderBy: [
      ['env', 'desc'],
      ['org', 'desc'],
    ],
  }), []);
  const patients = useCollection('patients', params);
  const {
    organizations,
    environments,
  } = useContext(DataContext);
  const appUsers = useCollection('app-users');

  const [showModal, setShowModal] = useState(false);
  const [patient, setPatient] = useState(null);
  const [search, setSearch] = useState("");

  const environmentMap = arrayDTO(environments);
  const organizationMap = arrayDTO(organizations);
  const appUsersMap = arrayDTO(appUsers);

  const onSearch = event => setSearch(event.target.value);
  const dataSource = patients.filter(patientName => {
    let patientNames;
    if (search === "") {
      patientNames = patientName;
    } else if (patientName.authData.patient.name.toLowerCase().includes(search.toLowerCase()))  {
      patientNames = patientName;
    }
    return patientNames
  }).map(patient => dataSourceDTO(patient, {
      environments: environmentMap,
      organizations: organizationMap,
      appUsers: appUsersMap,
    }));
  const { action = 'add', ...rawPatient } = patient || {};

  return (
    <>
      <PageHeader
        organizations={organizations}
        environments={environments}
        firebaseAction={patientActions}
        sectionName="Patients"
        ButtonsComponent={() => (
          <ButtonsComponent
            environments={environments}
            organizations={organizations}
          />
        )}
      />
      <Input placeholder="Search Patient" onChange={onSearch}
        suffix={<SearchOutlined />} allowClear style={{ width: 410 }} />
      <Table
        columns={getColumns({ environments, organizations, setPatient, setShowModal })}
        dataSource={dataSource}
        pagination={{ pageSize: 20 }}
        rowClassName={({ status}) => {
          return !status.active && 'patient-inactive';
        }}
      />
      {patient && <FormModal
        organizations={organizations}
        environments={(environments) ? environments : formMapper.org}
        showModal={showModal}
        setShowModal={setShowModal}
        data={rawPatient}
        setData={setPatient}
        onSave={patientActions[action]}
        sectionName="Patients"
        id="patients-form-modal"
        initialValuesMapper={formMapper}
        Form={PatientForm}
      />}
    </>
  );
};

export default Patients;
