import firebase from '../../firebase';
import { USERS } from './collections';

export const book = ({ id, status }, appUser) => firebase
  .firestore()
  .collection(USERS)
  .doc(id)
  .update({
    available: !status.available,
    availableInfo: status.available
      ? {
        user: appUser.email,
        manual: true,
        bookDate: new Date(),
      }
      : null,
  });

export const remove = ({ id, status }) => firebase
  .firestore()
  .collection(USERS)
  .doc(id)
  .update({
    active: !status.active,
  });

export const add = (user) => firebase
  .firestore()
  .collection(USERS)
  .add({
    ...user,
    active: true,
    available: true,
  });

export const edit = ({
  id, email, password, role, triage, env, org,
}) => firebase
  .firestore()
  .collection(USERS)
  .doc(id)
  .update({
    ...(env & { env }),
    ...(org & { org }),
    ...(email & { email }),
    ...(password & { password }),
    ...(role & { role }),
    ...(triage & { triage }),
  });

const user = {
  book,
  remove,
  add,
  edit,
};

export default user;
