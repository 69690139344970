import React from 'react';
import { Button, Col, Form, Input, Select, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const TEST_TYPES = [
  {
    label: 'End To End',
    value: 'e2e',
  },
  {
    label: 'API',
    value: 'api',
  },
];

const TestCaseForm = ({
  id = "test-case-form",
  form,
  onFinish,
  organizations,
  initialValues,
}) =>(
  <Form
    initialValues={initialValues}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    form={form}
    id={id}
    name={id}
    onFinish={onFinish}
  >
    <Form.Item
      label="Title"
      name="title"
      rules={[
        {
          required: true,
          message: 'Please input the title of the test case'
        }
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Description"
      name="description"
    >
      <Input.TextArea />
    </Form.Item>
    <Form.Item
      label="Type"
      name="type"
    >
      <Select>
        {TEST_TYPES.map(({ label, value }) => (<Select.Option key={value} value={value}>{label}</Select.Option>))}
      </Select>
    </Form.Item>
    <Form.List name="testIds">
      {(fields, { add, remove }) =>(
        <>
          {fields.map(field => (
            <Col key={field.key}>
              <Divider />
              <Form.Item
                {...field}
                name={[field.name, 'organization']}
                fieldKey={[field.fieldKey, 'organization']}
                label="Organization"
                rules={[{ required: true, message: 'Please select an organization' }]}
              >
                <Select>
                  {organizations.map(({ id, name }) => (<Select.Option key={id} value={id}>{name}</Select.Option>))}
                </Select>
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, 'id']}
                fieldKey={[field.fieldKey, 'id']}
                label="TestRail ID"
                rules={[{ required: true, message: 'Please input the TestRail ID for this organization' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          ))}
          <Divider />
          <Form.Item
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: '60%' }}
              icon={<PlusOutlined />}
            >
              Add Test ID
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  </Form>
);

export default TestCaseForm;