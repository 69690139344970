import { formatDistance, format } from 'date-fns';
import {
  filterDTO,
  valueEquality,
  getEnvironmentValue,
  RepoByDefault,
} from '../../utils';
import { Link } from 'react-router-dom';
import Avatar from 'antd/lib/avatar/avatar';
import { Tooltip } from 'antd';

export const dataSourceDTO = (testRuns, { environments, organizations, apiKeys }) => ({
  raw: testRuns,
  id: testRuns.id,
  environment: getEnvironmentValue({
    env: environments[testRuns.environment],
    org: organizations[testRuns.organization],
  }),
  apiKey: apiKeys[testRuns.apiKey],
  startDate: testRuns.startDate ? format(new Date(testRuns.startDate.seconds * 1000), 'MMM do, hh:mm aaaa') : '-',
  duration: `${!testRuns.endDate ? 'In Progress' : formatDistance(
    new Date(testRuns.startDate.seconds * 1000),
    new Date(testRuns.endDate.seconds * 1000),
  )}`,
  total: testRuns.testResults.length,
  failed: testRuns.testResults.filter(({ result }) => !result).length,
  prInfo: testRuns.prInfo,
});

export const getColumns = ({ organizations }) => [
  {
    title: 'ID',
    dataIndex: 'id',
    render: (id) => {
      return <Link to={`/test-run/${id}`}>{id}</Link>;
    },
  },
  {
    title: 'User',
    render: ({
      apiKey: { owner, name, user } = {},
      prInfo,
    }) => {
      return (
        <>
          <Tooltip title={user && user.displayName} placement="top">
            <Avatar src={user && user.photoURL}/>
          </Tooltip> {name}
          {prInfo && (
            <a
              href={`https://github.com/pagerinc/${prInfo.repoName || RepoByDefault}/pull/${prInfo.prNumber}`}
              target="_blank"
              rel="noreferrer"
            > #{prInfo.prNumber}</a>
          )}
        </>
      );
    },
  },
  {
    title: 'Environment',
    dataIndex: 'environment',
    key: 'environment',
    filters: organizations
      .reduce((acc, { environments }) => acc.concat(environments.filter(({ envName }) => Boolean(envName))), [])
      .map(filterDTO('envName')),
    onFilter: valueEquality('environment'),
  },
  {
    title: 'Date',
    dataIndex: 'startDate',
    key: 'startDate',
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
  },
  {
    title: 'Failed',
    dataIndex: 'failed',
    key: 'failed',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
  },
];

export const TEST_TYPES = [
  { id: 'UI', name: 'UI' },
  { id: 'API', name: 'API' },
];

const TRIGGER_NAME = [
  {
    id: 'UI',
    triggerName: 'playwright-automation-manual-trigger:run',
  },
  {
    id: 'API',
    triggerName: 'api-tests-manual-trigger:run',
  },
];

const TEST_TAGS = [
  {
    org: 'HORIZON',
    tags: [
      {
        product: 'CC',
        tag: '@hrz_cc',
      },
      {
        product: 'EA',
        tag: '@hrz_ea',
      },
    ],
  },
  {
    org: 'JOURNI',
    tags: [
      {
        product: 'CC',
        tag: '@pgr_cc',
      },
      {
        product: 'EA',
        tag: '@pgr_ea',
      },
    ],
  },
  {
    org: 'SURA',
    tags: [
      {
        product: 'CC',
        tag: '@sra_cc',
      },
      {
        product: 'EA',
        tag: '@sra_ea',
      },
    ],
  },
];

export const getTriggerName = id => {
  const trigger = TRIGGER_NAME.find((item) => item.id === id);
  if (!trigger) {
    throw new Error(`Unable to find trigger name for ${id}`);
  }
  return trigger.triggerName;
}

export const getTestTags = ({ org, product }) => {
  const tags = TEST_TAGS.find((item) => item.org === org);

  if (!tags) {
    throw new Error(`Unable to find test tags`);
  }
  const testTag = tags.tags.find((item) => item.product === product);
  if (!testTag) {
    throw new Error(`Unable to find product test tag`);
  }

  return testTag.tag;
};
